import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval, map, Observable, startWith, switchMap } from 'rxjs';
import { Chat, ChatMessage, ChatToken, IChatRequestBody, IChatsParams, IGetMessagesParams, ISendMessageBody } from './chat.interfaces';
import { IContentResponse } from '../../interfaces/global.interfaces';
import { removeAllEmptyValues } from '../../utils/remove-empty-values';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private endpoint = `chat`;
  private myChatEndpoint = 'my/chat';
  constructor(private http: HttpClient) { }

  public getChatById(chatId: number): Observable<Chat>{
    return this.http.get<Chat>(`${this.endpoint}/${chatId}`, { headers: { token: 'true' } });
  }

  public chatMessagesPolling(chatId: number, params: IGetMessagesParams): Observable<IContentResponse<ChatMessage>> {
    const newParams = removeAllEmptyValues(params);
    let httpParams = new HttpParams({ fromObject: newParams });

    return interval(3000).pipe(
      startWith(0),
      switchMap(() =>
        this.http.get<IContentResponse<ChatMessage>>(`${this.endpoint}/${chatId}/messages`, { headers: { token: 'true' }, params: httpParams })
      ),
      map((response:IContentResponse<ChatMessage>) => {
        // Sort response.items by createdAt ASC (oldest first)
        const sortedItems = response.items.sort((a, b) => {
          return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        });
  
        return {
          ...response,
          items: sortedItems  // Return sorted items
        };
      })
    );
  }

  public chatMessages(chatId: number, params: IGetMessagesParams): Observable<IContentResponse<ChatMessage>> {
    const newParams = removeAllEmptyValues(params);
    let httpParams = new HttpParams({ fromObject: newParams });
    return this.http.get<IContentResponse<ChatMessage>>(`${this.endpoint}/${chatId}/messages`, { headers: { token: 'true' }, params: httpParams }).pipe(
      map((response:IContentResponse<ChatMessage>) => {
        // Sort response.items by createdAt ASC (oldest first)
        const sortedItems = response.items.sort((a, b) => {
          return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        });
  
        return {
          ...response,
          items: sortedItems  // Return sorted items
        };
      })
    );
  }

  public create(body: IChatRequestBody): Observable<Chat>{
    return this.http.post<Chat>(`${this.endpoint}`, body, { headers: { token: 'true' } })
  }

  public delete(chatId: number): Observable<any>{
    return this.http.delete<any>(`${this.endpoint}/${chatId}`, { headers: { token: 'true' }
    }
  )
}


  public getAllChatsPolling(params: IChatsParams): Observable<IContentResponse<Chat>> {
    const newParams = removeAllEmptyValues(params);
    let httpParams = new HttpParams({ fromObject: newParams });
    
  
    return interval(4000).pipe(
      startWith(0),
      switchMap(() =>
        this.http.get<IContentResponse<Chat>>(this.endpoint, { headers: { token: 'true' }, params: httpParams }).pipe(
          map(data => {
          // Filter out items that don't have an updatedAt
          data.items = data.items.filter(item => item.updatedAt);
          
          // Sort remaining items by updatedAt in descending order
          data.items.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
          
          return data;
          })
        )
      )
    );

  }

  public getAll(params?: IChatsParams): Observable<IContentResponse<Chat>> {
    const newParams = removeAllEmptyValues(params);
    let httpParams = new HttpParams({ fromObject: newParams });
  
    return this.http.get<IContentResponse<Chat>>(this.endpoint, { headers: { token: 'true' }, params: httpParams }).pipe(
      map(data => {
      // Filter out items that don't have an updatedAt
      data.items = data.items.filter(item => item.updatedAt);
      
      // Sort remaining items by updatedAt in descending order
      data.items.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
      
      return data;
      })
    );
  }

  
  public sendMessage(chatId: number, body: ISendMessageBody): Observable<Chat>{
    return this.http.post<Chat>(`${this.endpoint}/${chatId}/message`, body, { headers: { token: 'true' } })
  }

  public seen(messageId: number): Observable<Chat>{
    return this.http.post<Chat>(`${this.endpoint}/${messageId}/seen`, {}, { headers: { token: 'true' } })
  }

  public markAllMyMessagesAsSeen(chatId: number): Observable<Chat>{
    return this.http.post<Chat>(`${this.myChatEndpoint}/${chatId}/mark-seen`, {}, { headers: { token: 'true' } })
  }

  public addUser(chatId: number, userId: number): Observable<Chat>{
    return this.http.post<Chat>(`${this.endpoint}/${chatId}/user`,{userId: userId}, { headers: { token: 'true' } })
  }


  public removeUser(chatId: number, userId: number): Observable<Chat>{
    return this.http.delete<Chat>(`${this.endpoint}/${chatId}/user`, { headers: { token: 'true' },
      body: { userId: userId }
    }
  )
}

  public getChatToken(): Observable<ChatToken>{
     return this.http.get<ChatToken>(`auth/chat-token`, { headers: { token: 'true' } });
   }

   public uploadFile(file: File, chatId: number){
    const formData = new FormData();
    formData.append('photo', file)
   // formData.append('width', '150');
   // formData.append('height', '150');
    return this.http.post(`${this.endpoint}/${chatId}/upload-file`, formData, { headers: { token: 'true'}});

   }


}
